
import moment from 'moment';
import { defineComponent, ref, watch } from 'vue';
import VueDatepickerUi from 'vue-datepicker-ui';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';

export default defineComponent({
  name: 'DatePickerGroup',
  props: {
    initialStartDate: {
      type: Date,
      required: true,
    },
    initialEndDate: {
      type: Date,
      required: true,
    },
    dayLimit: {
      type: Number,
      default: 31,
    },
  },
  components: {
    Datepicker: VueDatepickerUi,
  },
  emits: ['date-change'],
  setup(props, context) {
    const startDate = ref(props.initialStartDate);
    const endDate = ref(props.initialEndDate);
    const displayLimitMessage = ref(false);

    watch(
      () => [startDate.value, endDate.value],
      (
        [currentStartDate, currentEndDate],
        [previousStartDate, previousEndDate]
      ) => {
        // We are limiting the query to datelimit range tops, we don't want to time it out in the backend.
        // We create the reanges based on the previous values.
        let createdStartDate = moment(previousStartDate.toISOString())
          .utc()
          .format();
        const startDateLowerEndLimit = new Date(
          moment(createdStartDate).subtract(props.dayLimit, 'd').format()
        );
        const startDateUpperEndLimit = new Date(
          moment(createdStartDate).add(props.dayLimit, 'd').format()
        );
        let createdEndDate = moment(previousEndDate.toISOString())
          .utc()
          .format();
        const endDateLowerEndLimit = new Date(
          moment(createdEndDate).subtract(props.dayLimit, 'd').format()
        );
        const endDateUpperEndLimit = new Date(
          moment(createdEndDate).add(props.dayLimit, 'd').format()
        );

        // First bound check.
        // Start Date not within bounds
        let boundsModifiedDate = false;
        if (
          !(
            startDateLowerEndLimit.getTime() <= currentStartDate.getTime() &&
            currentStartDate.getTime() <= startDateUpperEndLimit.getTime()
          )
        ) {
          endDate.value = currentStartDate;
          boundsModifiedDate = true;
        }

        // End Date not within bounds
        if (
          !(
            endDateLowerEndLimit.getTime() <= currentEndDate.getTime() &&
            currentEndDate.getTime() <= endDateUpperEndLimit.getTime()
          )
        ) {
          startDate.value = currentEndDate;
          boundsModifiedDate = true;
        }

        // If the bound check modified the dates we don't want to run the checks below.
        if (!boundsModifiedDate) {
          if (currentStartDate >= currentEndDate) {
            // End Date was the one changed
            if (previousStartDate === currentStartDate) {
              startDate.value = currentEndDate;
            } else {
              endDate.value = currentStartDate;
            }
          } else if (currentEndDate < currentStartDate) {
            // Start Date was the one changed
            if (previousEndDate === currentEndDate) {
              endDate.value = currentStartDate;
            } else {
              startDate.value = currentEndDate;
            }
          }
        }

        displayLimitMessage.value = boundsModifiedDate;

        // Send data to parent component
        context.emit('date-change', {
          startDate: startDate.value,
          endDate: endDate.value,
        });
      }
    );

    return {
      startDate,
      endDate,
      displayLimitMessage,
    };
  },
});
